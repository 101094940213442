<template>
    <div class="box">

        <div class="headerFixed">
            <!-- 顶部搜索栏 -->
            <div class="searchBox">
                <div class="inputBox">
                    <img src="@/assets/img/search.svg" class="icon">
                    <input type="text" placeholder="请输入城市或拼音" class="input-style" v-model="citySearch">
                </div>
            </div>

            <!-- 当前定位 -->
            <div class="location-box">
                <div class="location-title">当前城市</div>
                <div class="location-content">
                    <div class="location-address">{{ city }}</div>
                    <div class="right-location" @click="location">
                        <img src="@/assets/img/orange_location.svg" alt="" class="icon">
                        重新定位
                    </div>
                </div>
            </div>

            <!-- 占位栏 -->
            <div class="line"></div>
        </div>
        <div class="cityList">
            <!-- <div class="hotCity">
                <div class="title">热门城市</div>

                <div class="hotBox">
                    <div class="hot-item" v-for="(item, index) in hotCity" :key="index" @click="changeCity(item)">
                        {{ item }}
                    </div>
                </div>
            </div> -->
            <!-- 城市列表 -->
            <div>
                <div id="showCityContent"></div>
                <div v-for="item in showCity" class="letter-item">
                    <div><a :id="item.letter">{{ item.letter }}</a></div>
                    <div v-for="i in item.citylist" @click="changeCity(i)">{{ i }} </div>
                </div>
            </div>
            <aside class="letter-aside">
                <ul>
                    <li v-for="item in showCity" @click="naver(item.letter)">{{ item.letter }} </li>
                </ul>
            </aside>
            <div id="tip">
                {{ tipString }}
            </div>
        </div>
    </div>
</template>


<script>
// import pinyin from 'pinyin'
import pinyin from '@/plugin/web-pinyin/bundle.js'
// import CityData from '../../assets/json/city.json'
import CityData from '../../assets/json/city2.json'
import { getCityList } from '@/api/city'
import AMapLoader from '@amap/amap-jsapi-loader';
import Location from '@/utils/location'

var cityLists = []
var cityNames = []
var cityNamesFilter = []
var letter = []
var countPage = 0
var tipLetter = ''
var citySearch = ''
var showCity = []
var showCityList = []
var showCityTemp

export default {
    beforeCreated () {
    },
    updated () {

    },
    created () {
        // countPage++

        this.getCityListData()

        let cityList = CityData.provinces
        for (let i in cityList) {
            cityLists = cityList[i].citys
            for (let j in cityLists) {
                cityNames.push(cityLists[j].citysName)
            }
        }
        cityNamesFilter = cityNames

        this.setMap()
        
        // this.someData = CityData
    },
    mounted: function () {
        window.scrollTo(0, 0)
        this.cityFilter(this.citySearch)
    },
    // 页面销毁时生命周期
    destroyed () {
         cityLists = []
         cityNames = []
         cityNamesFilter = []
         letter = []
         countPage = 0
         tipLetter = ''
         citySearch = ''
         showCity = []
         showCityList = []

         window.scrollTo(0, 0)
    },
    methods: {
        location(){
            this.setMap()
        },
         setMap(){
            AMapLoader.load({
                plugins: ['AMap.Geolocation'],
            }).then((AMap) => {
                Location.getCurrentLocation(AMap, (result) => {
                    // 如果城市是空，则取省份名称
                    this.city = result.addressComponent.city == "" ? result.addressComponent.province : result.addressComponent.city
                }, () => {
                    this.$toast("定位失败，请检查网络或GPS是否正常开启")
                })
            }).catch(e => {
                console.log(e);
            }, true);
        },
        getCityListData(){
            getCityList().then(res => {
                if (res.code == 10000) {
                    this.serverCityList = res.data
                }
            })
        },
        changeCity(v){
            //  对比本地城市与服务器城市code是否相同
            var cityInfo = this.serverCityList.find(item => item.name === v)
            if(cityInfo == null){
                this.$toast('该城市暂未开通，请选择其他城市')
                return
            }
            window.scrollTo(0, 0)
            sessionStorage.setItem('selectCity', JSON.stringify({ cityInfo: cityInfo }))
            this.$router.go(-1)
        },
        buildLetter () {  // 构建字母项
            letter = []
            for (let i = 0; i < 26; i++) {
                let obj = {}
                obj.letter = String.fromCharCode((65 + i))
                obj.citylist = []
                letter.push(obj)
            }
        },
        getFirstLetter (str) { //  得到城市第一个字的首字母
            return pinyin.pinyin(str)[0][0].charAt(0).toUpperCase()
        },
        buildItem (cityNamesFilter) {  // 构建城市
            this.buildLetter()
            let _this = this
            for (let i = 0; i < 26; i++) {
                letter[i].citylist = []
            }
            for (let i = 0; i < cityNamesFilter.length; i++) {
                let _index = Number(_this.getFirstLetter(cityNamesFilter[i]).charCodeAt() - 65)
                if (_index >= 0 && _index < 26) {
                    letter[_index].citylist.push(cityNamesFilter[i])
                }
            }
            // 如果letter中citylist中没有值的话，过滤这一项
            showCity = showCityTemp = letter.filter( (value) => {
                let len = value.citylist.length
                return len > 0
            })
        },
        naver (id) { // 点击右边字母滚动
            this.tipString = id
            let obj = document.getElementById(id)
            let tip = document.getElementById('tip')
            tip.setAttribute('class', 'tipAppear')
            setTimeout( () => {
                tip.removeAttribute('class')
            }, 500)
            let oPos = obj.offsetTop
            return window.scrollTo(0, oPos - 110)
        },
        cityFilter (city) {  // 城市搜索筛选
            let showCityListTemp
            this.buildItem(cityNamesFilter)
            showCity = showCityTemp
            showCity = showCity.filter( (value) => {
                showCityList = value.citylist
                showCityListTemp = showCityList.filter( (val) => {
                    return (val.indexOf(city) > -1)
                })
                value.citylist = showCityListTemp
                return value.citylist.length > 0
            })
            this.showCity = showCity
            if (showCity.length === 0) {
                let _showCityContent = document.getElementById('showCityContent')
                _showCityContent.innerText = '查询不到结果'
                _showCityContent.setAttribute('class', 'tipShow')
            } else {
                document.getElementById('showCityContent').innerText = ''
            }
        }
    },
    data() {
        return {
            city:"定位中...",
            cityNames: cityNamesFilter,
            letter: letter,
            tipString: tipLetter,
            citySearch: citySearch,
            showCity: showCity,
            hotCity:[
                '北京', '上海', '广东', '深圳', '济南', '重庆', '昆明'
            ],
            serverCityList:[]
        }
    },
    watch: {
        citySearch (newCitySearch) {
            this.cityFilter(newCitySearch)
        }
    }
}
</script>

<style lang="less">

@import "../../styles/base.less";

.box {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 3.5rem;

    .headerFixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: #fff;
    }

    .cityList {
        flex: 1;
        overflow-y: auto;
    }
}

.searchBox {
    .marginBox();
    background-color: #F5F5F5;
    border-radius: .5rem;
    padding: .25rem .6rem;
    display: flex;
    align-items: center;
    font-size: .35rem;

    .inputBox {
        flex: 1;
        display: flex;
        align-items: center;

        .icon {
            width: .45rem;
            height: .45rem;
            margin-left: .1rem;
        }

        .input-style {
            background-color: #F5F5F5;
            .input-style()

        }
    }
}

 .location-box {
     .marginBox();
     margin-top: .4rem;
     font-size: .35rem;

     .location-title {
         font-weight: bold;
         color: #989898;
     }

     .location-content {
         margin-top: .25rem;
         .flex-row-between();

         .location-name {
             color: #999;
         }

         .right-location {
             color: #F8A24D;

             .icon {
                 width: .3rem;
                 height: .3rem;
             }
         }
     }
 }

 .line {
     width: 100%;
     height: .2rem;
     background-color: #F5F5F5;
 }

 .cityList{
    .marginBox();
        font-size: .35rem;
    .hotCity {
        font-size: .35rem;
        .title{
            font-weight: bold;
            color: #989898;
            margin-bottom: .25rem;
        }

        .hotBox{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .hot-item{
                width: 1.8rem;
                height: .8rem;
                line-height: .8rem;
                text-align: center;
                border-radius: .5rem;
                margin-right: .3rem;
                margin-bottom: .3rem;
                background-color: #F5F5F5;
                color: #333;
            }
        }
    }
 }




.letter-item {
    font-size: .35rem;
    >div:first-child {
        
        line-height: 1rem;
        margin-bottom: 2px;
        border:none;
        color: #999999;
        font-weight: bold;
    }

    >div {
        line-height: 1.2rem;
        border-bottom: 1px solid #f2f2f2;
    }
}

.letter-aside {
    position: fixed;
    right: 10px;
    top: 4.3rem;

    ul {
        list-style: none;
        line-height: 1.4em;
        font-size: 14px;
        color: #aaa;
        li{
            text-align: center;
        }
    }
}

#tip {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(249, 249, 249, .5);
    width: 100px;
    height: 100px;
    z-index: 10;
    text-align: center;
    line-height: 100px;
    font-size: 50px;
    opacity: 0;
}

.tipAppear {
    animation: appearTip 1 linear 0.5s;
}

@keyframes appearTip {
    0% {
        opacity: 1;
    }

    80% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}

.search-city {
    text-align: center;
    padding: 5px 0;

    input {
        line-height: 24px;
        border-radius: 5px;
        outline: none;
    }
}

.tipShow {
    text-align: center;
    line-height: 60px;
    font-size: 16px;
    color: #bbbbbb;
}
</style>